<template>
  <el-dialog
    width="530px"
    title="修改密码"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose"
  >
    <el-form ref="pwdForm" inline label-width="80px" :model="pwdForm" :rules="pwdRules">
      <el-form-item label="原密码" prop="password">
        <el-input
          type="password"
          autocomplete="off"
          show-password
          v-model="pwdForm.password"
          placeholder="请输入原密码"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input
          type="password"
          autocomplete="off"
          show-password
          v-model="pwdForm.newPassword"
          placeholder="请输入新密码"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkNewPassword">
        <el-input
          type="password"
          autocomplete="off"
          show-password
          v-model="pwdForm.checkNewPassword"
          placeholder="请确认新密码"
          style="width: 400px"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="pwdLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import onLogout from '@/utils/onLogout.js';
import { resetPwd } from '@/api/general-user/person-center.js';

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,16}$/.test(value)) {
        callback(new Error('6~16位字母、数字或特殊字符组合'));
      } else {
        if (this.pwdForm.checkNewPassword !== '') {
          this.$refs.pwdForm.validateField('checkNewPassword');
        }
        callback();
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.pwdForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      dialogVisible: false,
      pwdLoading: false,
      pwdForm: {
        id: '',
        password: '',
        newPassword: '',
        checkNewPassword: ''
      },
      pwdRules: {
        password: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        checkNewPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { validator: validateCheckPass, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    open(userInfo) {
      this.pwdForm.id = userInfo.id;
      this.dialogVisible = true;
    },
    handleClose() {
      this.pwdForm.id = '';
      this.$refs.pwdForm.resetFields();
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$refs.pwdForm.validate(valid => {
        if (!valid) return;
        this.pwdLoading = true;
        resetPwd(this.pwdForm)
          .then(res => {
            // TODO: 登出
            onLogout();
          })
          .finally(() => {
            this.pwdLoading = false;
          });
      });
    }
  }
};
</script>
