<template>
  <el-dialog
    width="530px"
    title="修改信息"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="80px" :model="paramsForm" :rules="rules">
      <el-form-item label="头像">
        <upload-image v-if="dialogVisible" @get-file-id="getFileId"></upload-image>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="paramsForm.name" placeholder="请输入姓名" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="paramsForm.phone" placeholder="请输入手机号" style="width: 400px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleColse">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import UploadImage from '@/components/Upload/uploadImage.vue';
import { setItem } from '@/utils/storage.js';
import { postUserInfo,getUserBasicInfo } from '@/api/general-user/person-center.js';

export default {
  components: { UploadImage },
  data() {
    return {
      dialogVisible: false,
      confirmLoading: false,
      paramsForm: {
        id: '',
        name: '',
        phone: '',
        avatar: ''
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', tigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', tigger: 'blur' }]
      }
    };
  },
  methods: {
    open(data) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        const { id, name, phone, avatar } = data;
        this.paramsForm.id = id;
        this.paramsForm.name = name;
        this.paramsForm.phone = phone;
        this.paramsForm.avatar = avatar;
      });
    },
    getFileId(fileId) {
      this.paramsForm.avatar = fileId;
    },
    handleColse() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        postUserInfo(this.paramsForm)
          .then(res => {
            this.$message.success('修改基本信息成功！');
            this.dialogVisible = false;
            return getUserBasicInfo();
          })
          .then(res => {
            // this.userInfo = res.data;
            // setItem('userInfo', res.data);
            this.$emit('on-success');
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    }
  }
};
</script>
