<template>
  <div>
    <el-upload
      accept=".jpg, .jpeg, .png"
      list-type="picture-card"
      :limit="limit"
      :class="{hide:hideUpload}"
      :action="uploadApi"
      :headers="uploadHeaders"
      :data="{appBucketName: 'traffic-construction'}"
      :before-upload="beforeUpload"
      :on-preview="handlePictureCardPreview"
      :on-change="handleChange"
      :on-remove="handleRemove"
      :on-success="onSuccess"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';

export default {
  props: {
    limit: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      uploadApi: '/api/roses-pub-service/sysFileInfo/uploadResponse',
      token: '',
      tenantId: '',
      acceptList: ['image/jpg', 'image/jpeg', 'image/png'],
      hideUpload: false,
      dialogVisible: false,
      dialogImageUrl: ''
    };
  },
  computed: {
    uploadHeaders() {
      return {
        Authorization: 'Bearer ' + this.token,
        AppCode: 'traffic-construction',
        tenantId: this.tenantId
      };
    }
  },
  created() {
    this.token = getItem('token');
    this.tenantId = getItem('tenantInfo').tenantId;
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      const isImage = this.acceptList.includes(file.type);
      if (!isImage) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
      }
      return isImage;
    },
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limit;
    },
    handleRemove(file, fileList) {
      this.hideUpload = fileList.length >= this.limit;
      this.$emit('get-file-id', '');
    },
    onSuccess(response, file, fileList) {
      this.$emit('get-file-id', response.data.fileId);
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .hide .el-upload--picture-card {
  display: none;
}
</style>
