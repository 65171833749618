<template>
  <div>
    <div class="info-card">
      <div class="avatar">
        <img v-if="userInfo.avatar" :src="avatarSrc" alt />
        <div v-else class="backup">USER</div>
      </div>
      <div class="info">
        <p class="title">基本信息</p>
        <p class="info-item">
          <span class="item-label">姓名</span>
          <span class="item-content">{{ userInfo.name }}</span>
        </p>
        <p class="info-item">
          <span class="item-label">手机号码</span>
          <span class="item-content">{{ userInfo.phone }}</span>
        </p>
      </div>
    </div>
    <div class="action">
      <div class="action-item" style="width:400px" @click="handleEdit">
        <div class="icon">
          <i class="el-icon-mobile-phone"></i>
        </div>
        <div class="text">
          <p class="title">修改信息</p>
          <span class="desc">对姓名、手机、头像等相关信息进行修改</span>
        </div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="action-item" style="width:443px;padding-left: 70px" @click="handleResetPwd">
        <div class="icon" style="background: linear-gradient(135deg, #f56c6c, #f79818);">
          <i class="el-icon-lock"></i>
        </div>
        <div class="text">
          <p class="title">修改密码</p>
          <span class="desc">
            对账户登录密码进行修改，初始密码为
            <span class="number">abc123456</span>
          </span>
        </div>
      </div>
    </div>
    <!-- 编辑基本信息弹窗 -->
    <EditDialog ref="editRef" @on-success="onEditSuccess" />
    <!-- 修改密码弹窗 -->
    <ResetPwdDialog ref="resetRef" />
  </div>
</template>

<script>
import { imgPreview } from '@/config/index.js';
import { getItem } from '@/utils/storage.js';
// api
// components
import EditDialog from './editDialog.vue';
import ResetPwdDialog from './resetPwdDialog.vue';

export default {
  components: { EditDialog, ResetPwdDialog },
  data() {
    return {
      userInfo: {}
    };
  },
  computed: {
    avatarSrc() {
      return process.env.VUE_APP_API + imgPreview + this.userInfo.avatar;
    }
  },
  created() {
    this.userInfo = getItem('userInfo');
  },
  methods: {
    handleEdit() {
      this.$refs.editRef.open(this.userInfo);
    },
    onEditSuccess() {
      this.userInfo = getItem('userInfo');
    },
    handleResetPwd() {
      this.$refs.resetRef.open(this.userInfo);
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-divider--vertical {
  height: 40px;
  margin: 0;
}

.info-card {
  display: flex;
  align-items: center;
  padding-left: 28px;
  height: 180px;

  .avatar {
    width: 96px;
    height: 96px;
    margin-right: 32px;
    // background-color: skyblue;
    img {
      width: 100%;
      height: 100%;
    }

    .backup {
      width: 96px;
      height: 96px;
      background-color: #ddd;
      border-radius: 2px;
      line-height: 96px;
      text-align: center;
      color: #fff;
      letter-spacing: 2px;
    }
  }

  .info {
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #303133;
    p {
      margin: 0;
    }
    .title {
      font-weight: 700;
    }
    .info-item {
      font-size: 14px;

      .item-content {
        margin-left: 32px;
        color: #979fa8;
      }
    }
  }
}
.action {
  display: flex;
  margin-left: 28px;
  align-items: center;
  width: 844px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(48, 49, 51, 0.1);

  .action-item {
    box-sizing: border-box;
    display: flex;
    // flex: 1;
    height: 100%;
    align-items: center;
    padding-left: 30px;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.25s ease-in-out;

    &:hover {
      background-color: #d9ebff;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      margin-right: 12px;
      background: linear-gradient(135deg, #9f4dff, #005aab);
      border-radius: 4px;

      i {
        font-size: 26px;
        color: #fff;
      }
    }

    .text {
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      p {
        margin: 0;
      }

      .title {
        font-size: 15px;
        color: #303133;
      }

      .desc {
        font-size: 12px;
        color: #979fa8;

        .number {
          color: #005aab;
        }
      }
    }
  }
}
</style>
